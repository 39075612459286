
import React, { useEffect, useState } from "react";
import { Modal, Form, InputNumber, Spin } from "antd";
import { donation$, getUser, FieldValue } from "shared/Firedatabase";
import { nullifyProps } from "shared/Utils";

export default ({ visible, idProject, data, onCancel }) => {
  const user = getUser();
  const [isSpinning, setIsSpinning] = useState(false);
  const [form] = Form.useForm();
  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 }
  };

  const triggerFormSubmit = async () => {
    let formData
    try {
      formData = await form.validateFields();
    }
    catch (e) {
      return;
    }

    setIsSpinning(true);
    nullifyProps(formData);
    formData.idUser = getUser().uid;
    formData.idProject = idProject;
    formData.type = 'prayer';

    if (idProject) {
      formData.createdDts = FieldValue.serverTimestamp();
      await donation$.add(formData);
    }
    else {
      formData.createdDts = FieldValue.serverTimestamp();
      formData.status = 'active';
      await donation$.add(formData);
    }
    setIsSpinning(false);
    onCancel();
  }

  useEffect(() => {
    if (!visible) { return }

    form.resetFields();
    form.setFieldsValue(data);
  }, [visible, data, form])

  return  <Modal
    title="Time"
    visible={visible}
    onOk={triggerFormSubmit}
    onCancel={() => onCancel()}
    width={700}
  >
    <Spin spinning={isSpinning}>
      <p>
        I, <strong>{user.displayName}</strong>, will commit to praying or has already prayed the following number of times for this project:
      </p>
      <Form
        {...layout}
        form={form}
        name="basic"
      >
        <Form.Item
          label="Prayers"
          name="amount"
          rules={[{required: true}]}
        >
          <InputNumber min={0} />
        </Form.Item>
      </Form>
    </Spin>
  </Modal>
}