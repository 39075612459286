import React, { useState, useEffect } from 'react';
import './App.css';
import Login from 'pages/Login';
import AppLayout from 'pages/Layout';
import { BrowserRouter as Router } from 'react-router-dom';
import { app } from 'shared/Firedatabase';

function App() {

  const [loggedIn, setLoggedIn] = useState(false);

  const login = (userName, password) => {
    app.auth().signInWithEmailAndPassword(userName, password).catch(error => {
      // Handle Errors here.
      var errorMessage = error.message;
      alert(errorMessage);
      // ...
    });
  }

  const fbLogin = async _ => {
    const provider = new app.auth.FacebookAuthProvider();
    provider.addScope('email');
    try {
      await app.auth().signInWithPopup(provider)
    } catch (error) {
      alert(error.message);
    }
  }

  const googleLogin = async _ => {
    const provider = new app.auth.GoogleAuthProvider();
    provider.addScope('https://www.googleapis.com/auth/userinfo.email');
    provider.addScope('https://www.googleapis.com/auth/userinfo.profile');
    try {
      const result = await app.auth().signInWithPopup(provider)
      console.log(result);
    } catch (error) {
      alert(error.message);
    }
  }



  const logout = () => {
    app.auth().signOut();
  }

  function handleStatusChange(user) {
    if (user) {
      // User is signed in.
      setLoggedIn(true);
    } else {
      // User is signed out.
      setLoggedIn(false);
    }
  }

  useEffect(() => {
    return app.auth().onAuthStateChanged(handleStatusChange);
  }, [])

  return (
    <Router>
      <div className="App">
        {loggedIn
          ? <AppLayout onLogout={logout} />
          : <Login onLogin={login} fbLogin={fbLogin} googleLogin={googleLogin} />
        }
      </div>
    </Router>
  );
}

export default App;