import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Avatar } from 'antd';
import { project$ } from 'shared/Firedatabase';

const cached = new Map();
export default ({idProject}) => {

  const [project, setProject] = useState({});

  useEffect(() => {
    if (!idProject) return;
    let query = cached.get(idProject);
    if (!query) {
      query = project$.doc(idProject).get();
      cached.set(idProject, query);
    }
    query.then((doc) => {
      setProject({idProject, ...doc.data()});
    });
  }, [idProject])

  return <Link to={`/project/${project.idProject}`}>
    <Avatar src={project.imageUrl} />&nbsp;&nbsp;
    <span style={{verticalAlign: 'middle'}}>{project.name}</span>
  </Link>

};