import React, { useEffect, useState } from 'react';
import { Button, Progress, Modal } from 'antd';
import { donation$ } from 'shared/Firedatabase';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import CheckoutForm from 'components/CheckoutForm'
const stripePromise = loadStripe('pk_test_51Hkg7YHAWMtzPkJW0oE8L2xPZmxwQH2GlYytZNJvKLu34sCnl7NYwGRZ0qlMv1JDscsBq1hY3N69BHBY4oLHdlnn00xH1zGBiS');

export default ({project}) => {
  const [total, setTotal] = useState([]);
  const [percent, setPercent] = useState(0);
  const [paymentVisible, setPaymentVisible] = useState(false);

  useEffect(() => {
    if (!project) {
      return;
    }
    const donationsQuery = donation$.where('idProject', '==', project.idProject);
    const unsubscribe = donationsQuery.where('type', '==', 'money').onSnapshot(snapshot => {
      let total = 0;
      snapshot.forEach(doc => total += doc.get('amount') / 100);
      setTotal(total);
      setPercent((total / project.cost) * 100)
    });

    return unsubscribe;
  }, [project])

  return <>
    <h2>Funding</h2>
    <Progress type="circle" percent={percent} format={percent => `$${total}`} />
    <h3>Goal: ${project.cost}</h3>
    <Button type="primary" onClick={() => setPaymentVisible(true)}>Donate</Button>

    <Modal
      title="Payment"
      visible={paymentVisible}
      onCancel={() => setPaymentVisible(false)}
      okButtonProps={{ style: { display: 'none' } }}
      width={700}
    >
      {paymentVisible && <Elements stripe={stripePromise}>
        <CheckoutForm idProject={project.idProject} />
      </Elements>}
    </Modal>
  </>

};