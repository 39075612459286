import { storage$, category$, db, FieldValue } from "shared/Firedatabase";

export async function uploadFile(info, folder, filename) {
  const file = info.file;
  const fileExt = file.type.split('/').pop();
  const fileName = (filename || file.uid) + '.' + fileExt;
  const storageRef = storage$.ref();
  const imageRef = storageRef.child(`${folder}/${fileName}`);
  const snapshot = await imageRef.put(file);
  if (snapshot.state === 'success') {
    const url = await imageRef.getDownloadURL();
    return url;
  }
}

export function nullifyProps(data) {
  if (!data) { return }
  const keys = Object.keys(data);
  keys.forEach(key => {
    if (data[key] === undefined) {
      data[key] = null;
    }
    if (Array.isArray(data[key])) {
      data[key].forEach(item => nullifyProps(item))
    }
    else if (data[key] && typeof data[key] === 'object') {
      nullifyProps(data[key]);
    }
  });

  return data;
}

export const changeItemPosition = (direction, index, list) => {
  if (!Array.isArray(list)) { return [] }

  const newList = [...list];
  const [data] = newList.splice(index, 1);
  let targetIndex = index + direction;

  if (targetIndex < 0) {
    newList.push(data);
  }
  else if (targetIndex >= newList.length) {
    newList.unshift(data);
  }
  else {
    newList.splice(targetIndex, 0, data);
  }
  return newList;
}

export function truncateString(str, num) {
  if (!str) { return '' }
  if (str.length <= num) {
    return str
  }
  return str.slice(0, num) + '...'
}