import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/auth';
import 'firebase/functions';

const firebaseConfig = {
  apiKey: "AIzaSyAHNAa1sxrcYFvlHXJ_lTGh8CsAKOLgzGY",
  authDomain: "deoworks.firebaseapp.com",
  databaseURL: "https://deoworks.firebaseio.com",
  projectId: "deoworks",
  storageBucket: "deoworks.appspot.com",
  messagingSenderId: "360163482478",
  appId: "1:360163482478:web:9bff7d8e4ed87163ce7f7f",
  measurementId: "G-QLQX7K79PZ"
};

if (firebase.apps.length === 0) {
  firebase.initializeApp(firebaseConfig);
}

export const db = firebase.firestore();;
export const FieldValue = firebase.firestore.FieldValue;
export const GeoPoint = firebase.firestore.GeoPoint;

export const user$ = db.collection('user');
export const project$ = db.collection('project');
export const donation$ = db.collection('donation');
export const storage$ = firebase.storage();
export const app = firebase;
export const auth = firebase.auth;
export const getUser = () => firebase.auth().currentUser;

export const stripeDonation = async () => {
  const fn = firebase.functions().httpsCallable('stripeDonation');
  const result = await fn({text: 'test'});
  console.log(result);
};

export default db;