import React, { useState, useEffect, useRef } from "react";
import { Card, Row, Col, Input, Table } from "antd";
import { SearchOutlined } from '@ant-design/icons';
import { donation$, getUser } from "shared/Firedatabase";
import ProjectAvatar from 'components/ProjectAvatar';

export default props => {
  const columns = [
    {
      title: 'Project',
      dataIndex: 'idProject',
      render: (value, data) => <ProjectAvatar idProject={data.idProject} />
    },
    {
      title: 'Type',
      dataIndex: 'type',
      render: (value) => <span style={{textTransform: 'capitalize'}}>{value}</span>
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      render: (value, data) => data.type === 'money' ? `$${value/100}` : data.type === 'time' ? `${value} hrs` : `x${value}`
    },
  ];

  const [itemList, setItemList] = useState([]);
  const [isModalVisible, setModalVisible] = useState(false);
  const searchQuery = useRef();
  const originalList = useRef([]);

  const doSearch = text => {
    let query = text.toLowerCase();
    let results;
    if (query) {
      results = originalList.current.filter(item => {
        const text = `${item.name}|${item.type}`.toLowerCase();
        return text.indexOf(query) >= 0;
      });
    }
    else {
      results = originalList.current;
    }
    setItemList(results);
    searchQuery.current = query;
  }

  useEffect(() => {
    const user = getUser();
    const unsubscribe = donation$.where('idUser', '==', user.uid).orderBy('createdDts', 'desc').onSnapshot(snaps => {
      const itemList = [];
        snaps.forEach(snap => {
          const data = {
            idDonation: snap.id,
            ...snap.data()
          };
          itemList.push(data);
      });
      setItemList(itemList);
      originalList.current = [...itemList];
    });

    return unsubscribe;
  }, []);

  return <Card>
    <Row>
      <Col span={14}>
        <h2>Donations</h2>
      </Col>
      <Col span={6}>
        <Input
          prefix={<SearchOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
          type="search" placeholder="search" onChange={ev => doSearch(ev.target.value)}></Input>
      </Col>
    </Row>

    <Table rowKey="idDonation" dataSource={itemList} columns={columns} style={{ paddingTop: '30px' }} />

  </Card>
}
