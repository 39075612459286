
import React, { useEffect, useState } from "react";
import { Modal, Form, Input, InputNumber, Spin, Select } from "antd";
import { donation$, getUser, FieldValue } from "shared/Firedatabase";
import { nullifyProps } from "shared/Utils";

const { Option } = Select;

export default function ProjectModal({ visible, idProject, data, onCancel }) {
  const user = getUser();
  const [isSpinning, setIsSpinning] = useState(false);
  const [hasHours, setHasHours] = useState(false);
  const [form] = Form.useForm();
  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 }
  };

  const triggerFormSubmit = async () => {
    let formData
    try {
      formData = await form.validateFields();
    }
    catch (e) {
      return;
    }

    setIsSpinning(true);
    nullifyProps(formData);
    formData.idUser = getUser().uid;
    formData.idProject = idProject;
    formData.type = 'time';

    if (idProject) {
      formData.createdDts = FieldValue.serverTimestamp();
      await donation$.add(formData);
    }
    else {
      formData.createdDts = FieldValue.serverTimestamp();
      formData.status = 'active';
      await donation$.add(formData);
    }
    setIsSpinning(false);
    onCancel();
  }

  useEffect(() => {
    if (!visible) { return }
    const user = getUser();

    form.resetFields();
    form.setFieldsValue({email: user.email});
  }, [visible, data, form])

  return  <Modal
    title="Time"
    visible={visible}
    onOk={triggerFormSubmit}
    onCancel={() => onCancel()}
    width={700}
  >
    <Spin spinning={isSpinning}>
      <p>
        I, <strong>{user.displayName}</strong>, will commit the following for this project:
      </p>
      <Form
        {...layout}
        form={form}
        name="basic"
      >
        <Form.Item
          label="Task"
          name="task"
          rules={[{required: true}]}
        >
          <Select onChange={val => setHasHours(val === 'time')}>
            <Option value="proj">Project Management Task</Option>
            <Option value="fin">Finance Management Task</Option>
            <Option value="con">Consultant Task</Option>
            <Option value="time">Time</Option>
          </Select>
        </Form.Item>

        {hasHours &&
          <Form.Item
            label="Hours"
            name="amount"
            rules={[{required: true}]}
          >
            <InputNumber min={0}/>
          </Form.Item>
        }
        <Form.Item
          label="Contact Email"
          name="email"
        >
          <Input type="email" />
        </Form.Item>
        <Form.Item
          label="Contact Number"
          name="phone"
        >
          <Input type="tel" />
        </Form.Item>
      </Form>
    </Spin>
  </Modal>
}