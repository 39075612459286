import React, { useEffect, useState } from 'react';
import { Button, Progress } from 'antd';
import { donation$ } from 'shared/Firedatabase';
import TimeModal from 'components/TimeModal';

export default ({project}) => {
  const [total, setTotal] = useState([]);
  const [percent, setPercent] = useState(0);
  const [modalVisible, setModalVisible] = useState(false);

  useEffect(() => {
    if (!project) {
      return;
    }
    const donationsQuery = donation$.where('idProject', '==', project.idProject);
    const unsubscribe = donationsQuery.where('type', '==', 'time').onSnapshot(snapshot => {
      let total = 0;
      snapshot.forEach(doc => {
        const amount = doc.get('amount');
        if (typeof amount === 'number') {
          total += doc.get('amount')
        }
      });
      setTotal(total);
      setPercent((total / project.time) * 100)
    });

    return unsubscribe;
  }, [project])

  return <>
    <h2>Time</h2>
    <Progress type="circle" percent={percent} format={percent => `${total} hrs`} />
    <h3>Goal: {project.time} hours</h3>
    <Button type="primary" onClick={() => setModalVisible(true)}>Volunteer</Button>

    <TimeModal visible={modalVisible} idProject={project.idProject} onCancel={() => setModalVisible(false)} />
  </>

};