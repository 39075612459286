import React, { useState } from "react";
import { Layout, Menu } from 'antd';
import { FileTextOutlined, HomeOutlined, LogoutOutlined, TagsOutlined } from '@ant-design/icons';
import { Switch, Route, Link, Redirect } from "react-router-dom";
import Discover from "pages/Discover";
import Projects from "pages/Projects";
import ProjectDetails from "pages/ProjectDetails";
import ProjectDonors from "pages/ProjectDonors";
import Donations from "pages/Donations";
import Logo from '../logo.svg';

const { Content, Footer, Sider } = Layout;

//export default class AppLayout extends React.Component {
export default function AppLayout(props) {
  const [collapsed, setCollapsed] = useState(false);

  function onCollapse() {
    setCollapsed(!collapsed);
  };

  return (
    <Layout style={{ minHeight: '100vh' }} >
      <Sider collapsible collapsed={collapsed} onCollapse={onCollapse} style={{textAlign: 'left'}}>
        <div className="logo" style={{ paddingTop: '40px', paddingBottom: '10px', paddingLeft: '20px', color: 'white' }}>
          <img src={Logo} alt="App-Logo" width="90%" />
        </div>
        <Menu theme="dark" mode="inline" defaultSelectedKeys={['discover']}>
          <Menu.Item key="discover">
            <Link to="/discover">
              <HomeOutlined />
              <span>Discover</span>
            </Link>
          </Menu.Item>
          <Menu.Item key="projects">
            <Link to="/projects">
              <FileTextOutlined />
              <span>My Projects</span>
            </Link>
          </Menu.Item>
          <Menu.Item key="donations">
            <Link to="/donations">
              <TagsOutlined />
              <span>My Donations</span>
            </Link>
          </Menu.Item>
          <Menu.Item key="logout" onClick={props.onLogout}>
            <LogoutOutlined />
            <span>LogOut</span>
          </Menu.Item>
        </Menu>
      </Sider>
      <Layout>
        <Content style={{ margin: '40px' }}>
          <Switch>
            <Route path="/discover">
              <Discover />
            </Route>
            <Route path="/projects">
              <Projects />
            </Route>
            <Route path="/project/:idProject/donors">
              <ProjectDonors />
            </Route>
            <Route path="/project/:idProject">
              <ProjectDetails />
            </Route>
            <Route path="/donations">
              <Donations />
            </Route>
            <Route path="/">
              <Redirect to="/discover" />
            </Route>
          </Switch>
        </Content>
        <Footer style={{ textAlign: 'center' }}>©2020 Deoworks</Footer>
      </Layout>
    </Layout>
  );
}
