import React, { useState } from "react";
import { Card, Input, Button } from "antd";
import { LockOutlined, MailOutlined } from '@ant-design/icons';
import Logo from '../logo.svg';

export default function Login({ onLogin, fbLogin, googleLogin }) {

  const [userName, setUserName] = useState('');
  const [password, setPassword] = useState('');

  const formSubmit = (event) => {
    event.preventDefault();
    onLogin(userName, password);
  }

  return (
    <div className="Login" >
      <Card style={{ width: 400, paddingBottom: '20px'}}>
        <div style={{ paddingBottom: "12px", textAlign: 'center', backgroundColor: '#000000' }}>
          <img src={Logo} alt="logo" width={200} />
        </div>
        {/*
        <form onSubmit={formSubmit} >
          <div style={{ marginBottom: '16px' }}>
            <Input
              prefix={<MailOutlined type="mail" style={{ color: 'rgba(0,0,0,.25)' }} />}
              type="text" placeholder="Email" required onChange={e => setUserName(e.target.value)} />
          </div>
          <div style={{ marginBottom: '16px' }}>
            <Input
              prefix={<LockOutlined type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
              type="password" placeholder="Password" required
              onChange={e => setPassword(e.target.value)}
            />
          </div>
          <Button htmlType="submit" type="primary" block style={{backgroundColor: '#000'}}>
            <span>Login</span>
          </Button>
        </form>
        */}
        <Button htmlType="submit" block style={{backgroundColor: '#fff'}} onClick={fbLogin}>
          <span style={{color: '#000'}}>Facebook Login</span>
        </Button>
        <Button htmlType="submit" block style={{backgroundColor: '#fff'}} onClick={googleLogin}>
          <span style={{color: '#000'}}>Google Login</span>
        </Button>
      </Card>
    </div>
  )

}