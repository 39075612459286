
import React, { useEffect, useState } from "react";
import { Modal, Form, Input, InputNumber, Upload, Spin, Row, Col } from "antd";
import { project$, getUser, FieldValue } from "shared/Firedatabase";
import { nullifyProps, uploadFile } from "shared/Utils";
const { Dragger } = Upload;

export default function ProjectModal(props) {
  const [imageUrl, setImageUrl] = useState();
  const [isSpinning, setIsSpinning] = useState(false);
  const [form] = Form.useForm();
  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 }
  };

  const triggerFormSubmit = async () => {
    let formData
    try {
      formData = await form.validateFields();
    }
    catch (e) {
      return;
    }

    setIsSpinning(true);
    const idProject = props.data ? props.data.idProject : undefined;
    nullifyProps(formData);
    formData.idUser = getUser().uid;

    if (idProject) {
      formData.modifiedDts = FieldValue.serverTimestamp();
      await project$.doc(idProject).update(formData);
    }
    else {
      formData.createdDts = FieldValue.serverTimestamp();
      formData.status = 'active';
      await project$.add(formData);
    }
    setIsSpinning(false);
    props.onCancel();
  }

  const onDraggerDrop = async (info) => {
    setIsSpinning(true);
    let url = await uploadFile(info, 'Projects');
    setImageUrl(url);
    form.setFieldsValue({imageUrl: url});
    setIsSpinning(false);
  }

  useEffect(() => {
    if (!props.visible) { return }

    const data =props.data || {};
    setImageUrl(data.imageUrl);
    form.resetFields();
    form.setFieldsValue(data);
  }, [props.visible, props.data, form])

  return  <Modal
    title="Edit Category"
    visible={props.visible}
    onOk={triggerFormSubmit}
    onCancel={() => props.onCancel()}
    width={700}
  >
    <Spin spinning={isSpinning}>
      <Form
        {...layout}
        form={form}
        name="basic"
      >
        <Form.Item
          label="Project Name"
          name="name"
          rules={[{required: true}]}
        >
          <Input type="text" />
        </Form.Item>

        <Form.Item
          label="Description"
          name="description"
        >
          <Input type="text" />
        </Form.Item>

        <Form.Item
          label="Image URL"
          name="imageUrl"
        >
          <Input type="text" />
        </Form.Item>
  
        <Row style={{marginBottom: '24px'}}>
          <Col span={16} offset={8}>
            <Dragger accept="image/*" multiple={false} showUploadList={false} customRequest={onDraggerDrop}>
              <img alt="logo" src={imageUrl} height="150" style={{display: imageUrl ? 'inline' : 'none'}}/><br/>
              Drag image here
            </Dragger>
          </Col>
        </Row>

        <Form.Item
          label="Funds Needed ($)"
          name="cost"
        >
          <InputNumber/>
        </Form.Item>

        <Form.Item
          label="Volunteer Time Needed (hrs)"
          name="time"
        >
          <InputNumber/>
        </Form.Item>

        <Form.Item
          label="Prayer Needed (#)"
          name="prayer"
        >
          <InputNumber />
        </Form.Item>
      </Form>
    </Spin>
  </Modal>
}