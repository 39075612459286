import React, { useEffect, useState } from 'react';
import { Button, Progress } from 'antd';
import { donation$ } from 'shared/Firedatabase';
import PrayerModal from 'components/PrayerModal';

export default ({project}) => {
  const [total, setTotal] = useState([]);
  const [percent, setPercent] = useState(0);
  const [modalVisible, setModalVisible] = useState(false);

  useEffect(() => {
    if (!project) {
      return;
    }
    const donationsQuery = donation$.where('idProject', '==', project.idProject);
    const unsubscribe = donationsQuery.where('type', '==', 'prayer').onSnapshot(snapshot => {
      let total = 0;
      snapshot.forEach(doc => total += doc.get('amount'));
      setTotal(total);
      setPercent((total / project.prayer) * 100)
    });

    return unsubscribe;
  }, [project])

  return <>
    <h2>Prayer</h2>
    <Progress type="circle" percent={percent} format={percent => `${total}`} />
    <h3>Goal: {project.prayer} prayers</h3>
    <Button type="primary" onClick={() => setModalVisible(true)}>Pray</Button>

    <PrayerModal visible={modalVisible} idProject={project.idProject} onCancel={() => setModalVisible(false)} />
  </>

};