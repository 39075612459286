import React, { useState, useEffect } from 'react';
import { Avatar } from 'antd';
import { user$ } from 'shared/Firedatabase';

const cached = new Map();
export default ({idUser}) => {

  const [user, setUser] = useState({});

  useEffect(() => {
    if (!idUser) return;
    let query = cached.get(idUser);
    if (!query) {
      query = user$.doc(idUser).get();
      cached.set(idUser, query);
    }
    query.then((doc) => {
      setUser({idUser: idUser, ...doc.data()});
    });
  }, [idUser])

  return <>
    <Avatar src={user.photoURL} />&nbsp;&nbsp;
    <span style={{verticalAlign: 'middle'}}>{`${user.displayName} <${user.email}>`}</span>
  </>

};