import React, { useState, useEffect, useRef } from 'react';
import { Card, Row, Col, Input, Button } from 'antd';
import { EditOutlined, SearchOutlined, PlusOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { project$, getUser } from 'shared/Firedatabase';
import ProjectModal from 'components/ProjectModal';

export default props => {

  const originalList = useRef([]);
  const [itemList, setItemList] = useState([]);
  const [isModalVisible, setModalVisible] = useState(false);
  const [itemData, setItemData] = useState();
  const searchQuery = useRef();

  const showModal = (data) => {
    setItemData(data);
    setModalVisible(!isModalVisible);
  }
  
  const onModalCancel = () => {
    setModalVisible(false)
  }

  const doSearch = text => {
    let query = text.toLowerCase();
    let results;
    if (query) {
      results = originalList.current.filter(item => {
        const text = `${item.name}|${item.type}`.toLowerCase();
        return text.indexOf(query) >= 0;
      });
    }
    else {
      results = originalList.current;
    }
    setItemList(results);
    searchQuery.current = query;
  };

  useEffect(() => {
    const uid = getUser().uid;
    const unsubscribe = project$.where('idUser', '==', uid).orderBy('createdDts', 'desc').onSnapshot(snaps => {
      const itemList = [];
      snaps.forEach(snap => {
        const data = {
          idProject: snap.id,
          ...snap.data()
        };
        itemList.push(data);
      });
      setItemList(itemList);
      originalList.current = [...itemList];
      console.log(itemList);

      if (searchQuery.current) {
        doSearch(searchQuery.current);
      }
    });

    return unsubscribe;
  }, []);

  return <>
    <Row>
      <Col span={14}>
        <h2>Projects</h2>
      </Col>
      <Col span={6}>
        <Input
          prefix={<SearchOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
          type="search" placeholder="search" onChange={ev => doSearch(ev.target.value)}></Input>
      </Col>
      <Col span={4}>
        <Button style={{ marginLeft: '10px' }} onClick={() => showModal()}>
          <PlusOutlined />
          Add Project
        </Button>
      </Col>
    </Row>

    {itemList.map(item => (
      <Card title={item.name}
        key={item.idProject}
        extra={<Button onClick={_ => showModal(item)} icon={<EditOutlined />} />}
        style={{ marginTop: '40px' }}>
        {item.imageUrl && <p><img src={item.imageUrl} width={200}/></p>}
        <p>{item.description}</p>
        <Link to={`/project/${item.idProject}/donors`}><Button>View Donors</Button></Link>
      </Card>
    ))}

    <ProjectModal visible={isModalVisible} data={itemData} onCancel={onModalCancel} />
  </>
}
