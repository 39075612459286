import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { project$ } from 'shared/Firedatabase';
import { Card, Typography, Row, Col } from 'antd';
import MoneyDonations from 'components/MoneyDonations';
import TimeDonations from 'components/TimeDonations';
import PrayerDonations from 'components/PrayerDonations';
const { Title } = Typography;


const ProjectDetails = () => {
  const { idProject } = useParams();
  const [ project, setProject ] = useState();

  useEffect(() => {
    project$.doc(idProject).get().then(doc => {
      setProject({idProject, ...doc.data()});
    });
  }, [idProject]);

  return project ? <Card>
    <p><img src={ project.imageUrl } style={{ width: '100%' }}/></p>
    <Title level={1}>{ project.name }</Title>
    <Title level={5}>{ project.description }</Title>

    <div style={{marginTop: '40px'}}>
    </div>
    <Row>
      <Col span={8} style={{textAlign: 'center'}}>
        <MoneyDonations project={project} />
      </Col>
      <Col span={8} style={{textAlign: 'center'}}>
        <TimeDonations project={project} />
      </Col>
      <Col span={8} style={{textAlign: 'center'}}>
        <PrayerDonations project={project} />
      </Col>
    </Row>
  </Card> : null;
};

export default ProjectDetails;
